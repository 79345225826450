@font-face {
  font-family: "Product Sans";
  src: local("ProductSans"),
    url(./newUXAssets/fonts/ProductSans.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./newUXAssets/fonts/Inter.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.modalStyle > .ant-modal-content {
  padding: 32px 24px;
  border-radius: 10px;
}

.modalLesson > .ant-modal-content {
  border-radius: 8px;
}

.modalStyle > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}
